<template>
    <div class="login">
        <div class="login_left">
            <div class="login_left_img">
                <img alt="" src="../assets/images/1.png"/>
            </div>
            <div class="text">
                <div>申怡读书</div>
                <div>阅读是快乐的</div>
            </div>
        </div>
        <div class="login_right">
            <!-- <div class="right_img">
              <img src="../assets/images/logo@3x.png" alt="" />
            </div> -->
            <div class="right_title">
                <h2>代理商管理系统</h2>
            </div>
            <a-form-model
                ref="rulesForm"
                :label-col="{ span: 3 }"
                :model="form"
                :rules="rules"
                :wrapper-col="{ span: 23 }"
            >
                <a-form-model-item
                    ref="email"
                    label="账号"
                    labelAlign="left"
                    prop="email"
                >
                    <a-input
                        v-model="form.email"
                        :maxLength="32"
                        class="my_input"
                        placeholder="请输入账号"
                        @pressEnter="loginClick(form)"
                    >
                        <a-icon
                            slot="prefix"
                            style="color: rgba(0, 0, 0, 0.25)"
                            type="user"
                        />
                    </a-input>
                </a-form-model-item>
                <a-form-model-item ref="pwd" label="密码" labelAlign="left" prop="pwd">
                    <a-input-password
                        v-model="form.pwd"
                        :maxLength="32"
                        class="my_input"
                        placeholder="请输入密码"
                        type="password"
                        @pressEnter="loginClick(form)"
                    >
                        <a-icon
                            slot="prefix"
                            style="color: rgba(0, 0, 0, 0.25)"
                            type="lock"
                        />
                    </a-input-password>
                </a-form-model-item>
                <a-button
                    :disabled="loginLoading"
                    :loading="loginLoading"
                    type="primary"
                    @click="loginClick(form)"
                >
                    登录
                </a-button>
            </a-form-model>
        </div>
    </div>
</template>

<script>
import {JSEncrypt} from "jsencrypt";
import {message} from "ant-design-vue";
import {UserLoginApi, DictionaryApi} from "@/request/api/login";

export default {
    created() {
        localStorage.clear();
        sessionStorage.clear();
    },
    data() {
        return {
            loginLoading: false,
            key: `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDR9fKOSRTLAtABTQAuSljGQXkU0ZR3kpCsnxoiOfxEMCsuhJIKFKpxxDa0exnucGY5lS1g0QckvRODnxniiazNzOqNUv2z4G3VKpkXDUET2R17kjPchw6EaZZ4RZGzqhuRHj4vx/5Pxm3ET+j8gN3brlgeXjUzdM5gHTBw9uTXoQIDAQAB`,
            form: {
                email: "",
                pwd: "",
            },
            rules: {
                email: [{validator: this.validateEmail, trigger: "blur"}],
                pwd: [{validator: this.validatePwd, trigger: "blur"}],
            },
        };
    },
    methods: {
        // 账号的校验规则
        validateEmail(rule, value, callback) {
            let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
            if (value === "") {
                callback("输入的内容不能为空");
            } else if (!reg.test(value)) {
                callback("请输入正确的手机号码");
            } else {
                callback();
            }
        },
        // 密码校验规则
        validatePwd(rule, value, callback) {
            // let reg = /^(?=.*[a-zA-Z]+)(?=.*[0-9]+)[a-zA-Z0-9]+$/;
            if (value === "") {
                callback("输入的内容不能为空");
            } else {
                callback();
            }
        },
        // 密码加密
        encryption(msg, key) {
            let encryptor = new JSEncrypt(); // 创建加密对象实例
            //之前ssl生成的公钥，复制的时候要小心不要有空格
            encryptor.setPublicKey(key); //设置公钥
            let rsaPassWord = encryptor.encrypt(msg); // 对内容进行加密
            return rsaPassWord;
        },
        loginClick(from) {
            this.loginLoading = true;
            setTimeout(() => {
                this.loginLoading = false;
            }, 5000);
            this.$refs["rulesForm"].validate((result) => {
                if (result) {
                    // 验证通过发送请求
                    UserLoginApi({
                        account: from.email,
                        password: from.pwd //this.encryption(from.pwd, this.key),
                        // vcode: from.vcode
                    }).then(({code, data}) => {
                        console.log(code, data)
                        if (code == 200) {
                            this.loginLoading = false;
                            localStorage.setItem("token", data.jwtAuth);
                            localStorage.setItem("user", JSON.stringify(data.admin));
                            localStorage.setItem("powerListArr", JSON.stringify(data.menus))
                            // localStorage.setItem("headUrl", data.headUrl);
                            sessionStorage.setItem("ascriptionFlag", data.isAgent);
                            if (data.menus.length > 0) {
                                location.href = "/home";
                            } else {
                                message.error("您没有访问权限,请换账号登录", 3);
                            }
                        }
                    });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.login {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;

    .login_left {
        width: 50%;
        height: 100%;
        background-color: #ffd12c;

        .login_left_img {
            width: 80%;
            margin-left: 10%;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .text {
            width: 100%;
            font-family: HelloFont-WenYiHei, HelloFont;
            font-size: 80px;
            color: #fff;

            div {
                width: 100%;
                text-align: center;

                &:nth-child(1) {
                    text-shadow: 0px 4px 0px #ff9130;
                    font-weight: 700;
                    letter-spacing: 7px;
                }

                &:nth-child(2) {
                    font-size: 30px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #ffffff;
                    letter-spacing: 28px;
                    margin-left: 10px;
                }
            }
        }
    }

    .login_right {
        width: 50%;
        height: 100%;
        padding: 5%;
        box-sizing: border-box;

        .right_img {
            width: 30%;
            height: 10%;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .right_title {
            margin-top: 10%;

            h2 {
                font-size: 34px;
                font-weight: 700;
            }
        }
    }
}

.my_input {
    width: 450px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #fff2e5;
}

.ant-btn {
    width: 450px;
    height: 40px;
    background: linear-gradient(135deg, #ffd62a 0%, #ffa500 100%);
    box-shadow: 0px 4px 8px -2px #fff1a6;
    border-radius: 4px;
}

.ant-form {
    margin-top: 10%;
}

.ant-form-item-label {
    font-weight: 700;
}

.ant-btn-primary {
    border-color: #fff;
}

/deep/ .ant-input-affix-wrapper .ant-input {
    border: none;
    border-bottom: 1px solid white !important;
    box-shadow: none;
}
</style>
